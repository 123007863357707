@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes ImgBounce {
  0% {
    transform: rotateZ(360deg);
  }
}
.loadingIcon svg {
  -webkit-animation: ImgBounce 1s infinite;
  animation: ImgBounce 1s infinite;
  width: 20%;
  height: 20%;
}

@keyframes IconsMove {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
.iconMove {
  -webkit-animation: IconsMove 2s ease-in-out 0s infinite alternate;
  animation: IconsMove 2s ease-in-out 0s infinite alternate;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
}
/* .slider:before {
  content: '';
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider:before {
  left: 0;
  top: 0;
} 
.slider :after {
  content: '';
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
} */
.slideTrack {
  animation: scroll 40s linear infinite;
}

/* styles/globals.css */
.slant-clip-path {
  clip-path: polygon(100% 100%, 0 100%, 0 0);
}

.skewing {
  transform: skew(-6turn, 3deg);
  /* transform: skew(0.5rad); */
}
